import { FacilitatorUser } from "./facilitator.model";

export interface CaseStateModel {
  cases: CaseResponse[];
  loading: LoadingCaseState;
  error: any | null;
}

export interface CaseResponse {
  id: string
  name: string
  description: string
  sessionId: string
  facilitators?: FacilitatorUser
}

export interface CaseRequest {
  caseId?: string
  name: string
  sessionId: string
  id?: string
}

export enum LoadingCaseState {
  loadingList = "loadingList",
  loadingAddUpdate = "loadingAddUpdate",
  loadingDelete = "loadingDelete",
  notLoading = "notLoading",
}