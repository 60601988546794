export interface TrackManagementStateModel {
  trackManagement: TrackManagementResponse[];
  loading: LoadingTrackManagementState;
  error: any | null;
}

export interface TrackManagementResponse {
  id?: string;
  trackName?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface ResponseData {
  data: TrackManagementResponse[];
}

export interface TrackManagementRequest {
  trackName?: string;
  id?: string;
}
export enum LoadingTrackManagementState {
  loadingList = 'loadingList',
  loadingAddUpdate = 'loadingAddUpdate',
  loadingDelete = 'loadingDelete',
  notLoading = 'notLoading',
}
