import { Injectable } from '@angular/core';

export interface SchoolsStateModel {
    loading: LoadingSchoolsState,
    selectedSchool: SchoolsModel | null,
    schools: SchoolsModel[],
    error: any | null,
}


export interface SchoolsModel {
    id: string
    name: string
}


export enum LoadingSchoolsState {
    loadingList = "loadLearningList",
    loadingAddUpdate = "loadLearningForm",
    loadingDelete = "loadLearningDelete",
    notLoading = "notLoadingLearning",
}