export interface OrientationModel {
    _id: string;
    id: string;
    type: string;
    date: Date;
    time: Date;
    orientationNo: string;
    registrations: number;
    isActive: boolean;
}

export interface OrientationSubmitRequest {
    type: string;
    date: string;
    time: string;
    registrations: number;
    orientationNo: string;
    isActive: boolean;
    id: string;
}

export interface OrientationListRequest {
    time: string;
    type: string;
}

export interface OrientationStateModel {
    orientations: OrientationModel[];
    loading: LoadingOrientationState;
    error: any | null;
}


export enum LoadingOrientationState {
    loadingList = "loadOrientationList",
    loadingAddUpdate = "loadOrientationForm",
    loadingDelete = "loadOrientationDelete",
    notLoading = "notLoadingOrientation",
}