import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { CaseRequest } from '@tsin-core/models/case.model';
import { environment } from '@tsin-env/environment';

const CASE_URL = `${environment.learningBaseUrl}/cases`;
const CASE_URL_ACTION = `${environment.learningBaseUrl}/cases/single`;

@Injectable({
  providedIn: 'root',
})
export class CaseService {
  http: HttpClient = inject(HttpClient);

  createCases(payload: CaseRequest) {
    return this.http.post(CASE_URL, payload);
  }

  updateCases(payload: CaseRequest) {
    return this.http.put(CASE_URL, payload);
  }

  getCases() {
    return this.http.get(CASE_URL);
  }

  getCase(id: string) {
    return this.http.get(CASE_URL_ACTION + `/${id}`);
  }

  deleteCase(id: string) {
    return this.http.delete(CASE_URL_ACTION + `/${id}`);
  }

}
