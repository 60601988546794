import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AssessmentFlagRequest, AssessmentStateRequest, ListAssessmentReq } from '@tsin-core/models/assessment.model';
import { environment } from '@tsin-env/environment';

const ASSESSMENT_URL = `${environment.learningBaseUrl}/assessments`;
const ASSESSMENT_URL_ACTION = `${environment.learningBaseUrl}/assessments/single`;
// const ASSESSMENT_LIST_USERS = `${environment.learningBaseUrl}/session-users/assessment`;
const ASSESSMENT_LIST_USERS = `${environment.learningBaseUrl}/session-users/leaders`;

@Injectable({
  providedIn: 'root',
})
export class AssessmentService {
  http: HttpClient = inject(HttpClient);

  addAssessment(payload: AssessmentStateRequest) {
    return this.http.post(ASSESSMENT_URL, payload);
  }

  flagAssessment(payload: AssessmentFlagRequest) {
    return this.http.post(ASSESSMENT_URL + `/flag`, payload);
  }

  updateFlagAssessment(payload: AssessmentFlagRequest) {
    return this.http.put(ASSESSMENT_URL + `/flag`, payload);
  }

  relaseSingleResult(resultId: string) {
    return this.http.patch(ASSESSMENT_URL + `/release-result/${resultId}`, { isReleased: true })
  }

  releaseAllResults() {
    return this.http.patch(ASSESSMENT_URL + `/release-result/all`, { isReleased: true });
  }

  getAssessment(id: string) {
    return this.http.get(ASSESSMENT_URL_ACTION + `/${id}`);
  }

  getAssessmentList(payload: ListAssessmentReq) {
    return this.http.get(ASSESSMENT_LIST_USERS + `/${payload.weeklyId}/${payload.sessionId}`);
  }

  getAssessmentByWeeklySchedule(weeklyScheduleId: string) { 
    return this.http.get(ASSESSMENT_LIST_USERS + `/${weeklyScheduleId}/all`);
  }
}
