import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthRequestModel } from '@tsin-core/models/auth.model';
import { environment } from '@tsin-env/environment';
import { ChangePasswordRequest } from '@tsin-core/models/profile.model';

const AUTH_VALIDATE_USER = `${environment.userBaseUrl}/auth/authenticate-profiile`;
const AUTH_LOGOUT = `${environment.userBaseUrl}/auth/authenticate-profiile`;
const AUTH_CHANGE_PASSWORD = `${environment.userBaseUrl}/auth/change-password`;

@Injectable({
    providedIn: 'root'
})

// The authentication service
export class AuthService {

    // Inject the http client into auth service
    http: HttpClient = inject(HttpClient);

    // Method for endpoint to validate the user and return a token
    validate(credentials: AuthRequestModel) {
        console.log('CREDENTIALS', credentials)
        return this.http.post(AUTH_VALIDATE_USER, credentials)
    }

    changePassword(credentials: ChangePasswordRequest) {
        const { confirmNewPassword, ...passwordCredentials } = credentials;
        console.log('Change PAssword', passwordCredentials);
        return this.http.post(AUTH_CHANGE_PASSWORD, passwordCredentials)
    }

    // Method for endpoint to logout a user from the server
    logout() {
        return this.http.delete(AUTH_LOGOUT)
    }
}