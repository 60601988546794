import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ScheduleByIdSessionRequest, WeeklyScheduleModel, WeeklyScheduleRequest } from '@tsin-core/models/schedule.model';
import { environment } from '@tsin-env/environment';
import { Observable } from 'rxjs';

const SCHEDULE_URL = `${environment.learningBaseUrl}/schedules`;

@Injectable({
  providedIn: 'root',
})

export class ScheduleService {

  http: HttpClient = inject(HttpClient);

  createSchedule(payload: any) {
    return this.http.post(SCHEDULE_URL, payload);
  }

  getSchedule() {
    return this.http.get(SCHEDULE_URL);
  }

  createWeeklySchedule(payload: Omit<WeeklyScheduleRequest, 'id'>) {
    return this.http.post(SCHEDULE_URL + `/weekly`, payload);
  }

  getWeeklySchedule(): Observable<WeeklyScheduleModel[]> {
    return this.http.get<WeeklyScheduleModel[]>(SCHEDULE_URL + `/weekly`);
  }

  getWeeklyScheduleByLearning(learningId: string) {
    return this.http.get(SCHEDULE_URL + `/weekly/learning/${learningId}`);
  }

  updateMemberGroup(groupName: string, user: any) {
    return this.http.put(SCHEDULE_URL + `/members`, { ...user, group: groupName, });
  }

  updateMemberTrack(groupName: string, track: string, user: any) {
    return this.http.put(SCHEDULE_URL + `/members`, { ...user, group: groupName, track: track });
  }

  updateLeaderGroup(user: any) {
    return this.http.put(SCHEDULE_URL + `/leaders`, user);
  }

  updateWeeklySchedule(payload: any) {
    return this.http.put(SCHEDULE_URL + `/weekly`, payload);
  }

  getSingleWeeklySchedule(id: any) {
    return this.http.get(SCHEDULE_URL + `/weekly/single/${id}`);
  }

  getScheduleByIdSession(payload: ScheduleByIdSessionRequest): Observable<any> {
    return this.http.get(SCHEDULE_URL + `/weekly/single/${payload.id}/${payload.sessionId}`);
  }

  deleteSingleWeeklySchedule(id: any) {
    return this.http.delete(SCHEDULE_URL + `/weekly/single/${id}`);
  }

  addWeeklySchedule(payload: any) {
    return this.http.post(SCHEDULE_URL + `/weekly/add-schedule`, payload);
  }

  removeWeeklySchedule(payload: any) {
    return this.http.delete(SCHEDULE_URL + `/weekly/remove-schedule`, payload);
  }

  generateTracks(payload: any) {
    return this.http.post(SCHEDULE_URL + `/generate-tracks`, payload);
  }

  getSingleSchedule(id: any) {
    return this.http.get(SCHEDULE_URL + `/single/${id}`);
  }

  deleteSingleSchedule(id: any) {
    return this.http.get(SCHEDULE_URL + `/single/${id}`);
  }

  getLeaders(learningId: any) {
    return this.http.get(SCHEDULE_URL + `/leaders/${learningId}`);
  }
}
