import { LoadingSchoolsState } from "@tsin-core/models/schools.model";

 
export class GetSchools {
    static readonly type = '[Schools] Get Schools';
}

export class GetSchool {
    static readonly type = '[Schools] Get a Single Result';
    constructor(public schoolId: string) { }
}

export class AddSchool {
    static readonly type = '[Schools] Add a new School';
    constructor(public payload: any) { }
}

export class UpdateSchool {
    static readonly type = '[Schools] Update a School';
    constructor(public payload: any) { }
}

export class DeleteSchool {
    static readonly type = '[Schools] Delete a single School';
    constructor(public schoolId: string) { }
}


export class SelectSchool {
    static readonly type = '[Schools] Select Result';
    constructor(public id: string) { }
}

export class SetSchoolsLoading {
    static readonly type = '[Schools] Set Schools Loading';
    constructor(public loading: LoadingSchoolsState) { }
}

export class SetSchoolsError {
    static readonly type = '[Schools] Set Schools Error';
    constructor(public error: string | null) { }
}
