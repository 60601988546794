import { ChangePasswordRequest, LoadingProfileState, ProfileRequest } from "@tsin-core/models/profile.model";

export class SetProfile {
    static readonly type = '[Profile] SetProfile';
    constructor(public payload: Omit<ProfileRequest, 'id'>) { }
}

export class UpdateProfile {
    static readonly type = '[Profile] Update Profile';
    constructor(public payload: ProfileRequest) { }
}

export class GetProfile {
    static readonly type = '[Profile] Get One';
    constructor(public id: string) { }
}

export class UpdatePassword {
    static readonly type = '[Profile] Update Password';
    constructor(public payload: ChangePasswordRequest) { }
}

export class SetLoading {
    static readonly type = '[Profile] Set Loading';
    constructor(public loading: LoadingProfileState) { }
}

export class SetError {
    static readonly type = '[Profile] Set Error';
    constructor(public error: string | null) { }
}
