import { CaseResponse } from "./case.model";

export interface AssessmentStateModel {
  assessment: AssessmentStateRequest[];
  userList: AssessmentUser[];
  selectedUser: AssessmentUser | null;
  selectedAsessmentFilter: ListAssessmentReq | null;
  loading: LoadingAssessmentState;
  error: any | null;
}

export interface AssessmentStateRequest {
  leaderId: string,
  caseId: string,
  userId: string,
  communicatesClearly: number,
  usesAppropriateNonVerbal: number,
  gathersRelevantInformation: number,
  prioritizesNeeds: number,
  respondsAppropriately: number,
  reflectsOnSimulations: number,
  analyzePatientNeeds: number,
  identifiesResources: number,
  overallMedicalExpert: number,
  comment: string,
  facilitatorId: string,
  assessmentStatus: "NOT_STARTED" | "STARTED",
  weeklyScheduleId: string
}

export interface Communicator {
  communicatesClearly: number;
  usesAppropriateNonVerbal: number;
  gathersRelevantInformation: number;
}

export interface Professional {
  prioritizesNeeds: number;
  respondsAppropriately: number;
  reflectsOnSimulations: number;
}

export interface HealthAdvocate {
  analyzePatientNeeds: number;
  identifiesResources: number;
}

export interface ListAssessmentReq {
  weeklyId: string;
  sessionId: string;
}

export interface AssessmentUser {
  asynchronousActivities: string[];
  id: string;
  sessionId: string;
  caseId: string;
  weeklyScheduleId: string;
  learningId: string;
  userId: string;
  userName: string;
  PGYLevel: string | null;
  case: CaseResponse;
  track: string;
  group: string;
  applicationId: string;
  associatedMedicalSchool: string;
  postgraduateTrainingProgram: string;
  attendance: string;
  comment: string | null;
  assessment: Assessment | null;
  application: AssessmentUserApp | null
}

export type AssessmentUserApp = {
  associatedMedicalSchool: string;
  postgraduateTrainingProgram: string;
  applicationNo: string;
  learning: {
    learningNo: string;
    startDate: Date;
    endDate: Date;
    name: string;
    id: string;
  } | null
}

export type Assessment = {
  data: AssessmentUserData | null;
  id: string;
  createdAt: string;
  updatedAt: string;
  facilitatorId: string;
  assessmentStatus: AssessmentStatusEnum; // Enum-like string type for status
  caseId: string;
  userId: string;
  leaderId: string;
  weeklyScheduleId: string;
  isReleased: boolean,
  flags: AssessmentFlag[] | undefined; //
};

export type AssessmentFlag = {
  id: string;
  details: string;
  flaggedBy: string;
  assessmentId: string;
  createdAt: Date;
}

export type AssessmentUserData = {
  communicatesClearly: number;
  usesAppropriateNonVerbal: number;
  gathersRelevantInformation: number;
  prioritizesNeeds: number;
  respondsAppropriately: number;
  reflectsOnSimulations: number;
  analyzePatientNeeds: number;
  identifiesResources: number;
  overallMedicalExpert: number;
  comment: string;
}

export type AssessmentFlagRequest = {
  assessmentId: string,
  details: string
}

export enum AssessmentStatusEnum {
  NOT_STARTED = "Not Started",
  IN_PROGRESS = "In Progress",
  COMPLETED = "Completed",
  RELEASED = "Released"
}

export enum LoadingAssessmentState {
  loadingList = 'loadingList',
  loadingAddUpdate = 'loadingAddUpdate',
  loadingDelete = 'loadingDelete',
  notLoading = 'notLoading',
}
