export interface UploadCSVModel {
    _id: string;
    id: string;
    fileName: string;
    createdAt: Date;
    total: number;
    fileUrl: string;
    records: UploadRecord[];
}

export interface UploadRecord {
    status: "Error" | "Success",
    data?: string,
    reason: string
}

export interface UploadCSVStateModel {
    history: UploadCSVModel[];
    loading: LoadingUploadCSVState;
    error: any | null;
}


export enum LoadingUploadCSVState {
    loadingList = "loadUploadCSVList",
    loadingAddUpdate = "loadUploadCSVForm",
    loadingDelete = "loadUploadCSVDelete",
    notLoading = "notLoadingUploadCSV",
}