import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ReleaseSingleResult } from '@tsin-core/actions/results.action';
import { ResultBySchoolRequest, ResultModel, SingleResultRequest } from '@tsin-core/models/results.model';
import { environment } from '@tsin-env/environment';
import { Observable } from 'rxjs';

const RESULT_URL = `${environment.learningBaseUrl}/results`;


@Injectable({
  providedIn: 'root'
})
export class ResultsService {

  http: HttpClient = inject(HttpClient);

  releaseSingleResult(payload: SingleResultRequest) {
    return this.http.patch(RESULT_URL + `/release-result/user/${payload.weeklyScheduleId}/${payload.userId}`, payload);
  }

  getResults() {
    return this.http.get(RESULT_URL);
  }

  getResultsBySchool(payload: ResultBySchoolRequest): Observable<ResultModel[]> {
    return this.http.get<ResultModel[]>(RESULT_URL + `/school/${payload.weeklyScheduleId}/${payload.schoolId}`);
  }

  getResultsByUser(userId: string) {
    return this.http.get(RESULT_URL + `/single/user/${userId}`);
  }

  deleteSession(id: string) {
    return this.http.delete(RESULT_URL + `/single/${id}`);
  }
}
