import { AssessmentStateRequest, ListAssessmentReq, LoadingAssessmentState } from '@tsin-core/models/assessment.model';

export class AddAssessment {
  static readonly type = '[Assessment] Add';
  constructor(public payload: AssessmentStateRequest) {}
}

export class GetAssessment {
  static readonly type = '[Assessment] Get One';
  constructor(public id: string) {}
}

export class ListAssessmentUsers {
  static readonly type = '[Assessment] Get Assessment Users';
  constructor(public payload: ListAssessmentReq) {}
}

export class SelectAssessmentUser{
  static readonly type = '[Assessment] Select Assessment User';
  constructor(public id: string) {}
}

export class SetLoading {
  static readonly type = '[Assessment] Set Loading';
  constructor(public loading: LoadingAssessmentState) { }
}

export class SetError {
  static readonly type = '[Assessment] Set Error';
  constructor(public error: string | null) { }
}