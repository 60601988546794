import { AssessmentFlagRequest, AssessmentStateRequest, ListAssessmentReq, LoadingAssessmentState } from '@tsin-core/models/assessment.model';

export class AddAssessment {
  static readonly type = '[Assessment] Add';
  constructor(public payload: AssessmentStateRequest) { }
}

export class GetAssessment {
  static readonly type = '[Assessment] Get One';
  constructor(public id: string) { }
}

export class ListAssessmentUsers {
  static readonly type = '[Assessment] Get Assessment Users';
  constructor(public payload: ListAssessmentReq) { }
}

export class GetAssessmentByWeeklySchedule {
  static readonly type = '[Assessment] Get Assessment By Weekly Schedule';
  constructor(public weeklyScheduleId: string) { }
}

export class SelectAssessmentUser {
  static readonly type = '[Assessment] Select Assessment User';
  constructor(public id: string) { }
}

export class FlagAssessment{
  static readonly type = '[Assessment] Flag Assessment';
  constructor(public payload: AssessmentFlagRequest) { }
}

export class ReleaseSingleResult{
  static readonly type = '[Assessment] Release single result';
  constructor(public id: string) { }
}

export class ReleaseAllResults{
  static readonly type = '[Assessment] Release all results'; 
}

export class SetSelectionFilter {
  static readonly type = '[Assessment] Set Selection Filter';
  constructor(public selection: ListAssessmentReq) { }
}

export class ResetSelectionFilter {
  static readonly type = '[Assessment] Reset Selection Filter';
}

export class SetLoading {
  static readonly type = '[Assessment] Set Loading';
  constructor(public loading: LoadingAssessmentState) { }
}

export class SetError {
  static readonly type = '[Assessment] Set Error';
  constructor(public error: string | null) { }
}