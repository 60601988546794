import { LearningType } from "@tsin-core/entities";
import { LoadingUploadCSVState } from "@tsin-core/models/upload-csv.model";
   

export class DeleteUploadCSV {
    static readonly type = '[UploadCSV] Delete';
    constructor(public id: string) { }
}

export class GetUploadCSVs {
    static readonly type = '[UploadCSV] Get';
    constructor() { }
}
  
export class SetUploadCSVLoading {
    static readonly type = '[UploadCSV] Set Loading';
    constructor(public loading: LoadingUploadCSVState) { }
}

export class SetUploadCSVError {
    static readonly type = '[UploadCSV] Set Error';
    constructor(public error: string | null) { }
}
