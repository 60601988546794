import { LearningType } from "@tsin-core/entities";
import { ApplicationModel, ApplicationStatusUpdateRequest, ApplicationSubmitRequest, LoadingApplicationState, ReassignLearnersRequest } from "@tsin-core/models/application.model";
import { LearningListRequest } from "@tsin-core/models/learning.model";

export class CheckUserRegistrations {
    static readonly type = '[Application] Check User Registrations';
    constructor(public learningType: LearningType) { }
}

export class AddApplication {
    static readonly type = '[Application] Add';
    constructor(public payload: Omit<ApplicationSubmitRequest, 'id'>) { }
}

export class UpdateApplication {
    static readonly type = '[Application] Update';
    constructor(public payload: ApplicationSubmitRequest) { }
}

export class GetUserApplication {
    static readonly type = '[Application] GET Single Application';
    constructor(public payload: string) { }
}


export class GetUserApplicationByLearner {
    static readonly type = '[Application] GET Application By Learner';
    constructor(public learningType: LearningType) { }
}


export class GetLearnersByLearning {
    static readonly type = '[Application] GET Learners By Learning Id';
    constructor(public learningId: string) { }
}

export class DeleteApplication {
    static readonly type = '[Application] Delete';
    constructor(public id: string) { }
}

export class GetApplications {
    static readonly type = '[Application] Get';
    constructor(public payload: LearningListRequest) { }
}

export class GetApplication {
    static readonly type = '[Application] Get One';
    constructor(public id: string) { }
}

export class UpdateLearningLearnerStatus{
    static readonly type = '[Application] Update Learning Learner Status';
    constructor(public payload: ApplicationStatusUpdateRequest) { }
}

export class ReassignLearners{
    static readonly type = '[Application] Reassign Learners';
    constructor(public payload: ReassignLearnersRequest) { }
}

export class GetLearningFee {
    static readonly type = '[LearningFees] Get One';
    constructor(public payload: string) { }
}

export class DisableForm {
    static readonly type = '[Form] Disable Form';
}

export class ShowErrorMessage {
    static readonly type = '[Error] Show Error Message';
}

export class SetSingleApplication {
    static readonly type = '[Application] Set Single Application';
    constructor(public payload: ApplicationModel) { }
}


export class SetApplicationLoading {
    static readonly type = '[Application] Set Loading';
    constructor(public loading: LoadingApplicationState) { }
}

export class SetApplicationError {
    static readonly type = '[Application] Set Error';
    constructor(public error: string | null) { }
}
