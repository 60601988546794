import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, shareReplay } from "rxjs";
import { environment } from '@tsin-env/environment';
import { ApplicationModel } from '@tsin-core/models/application.model';


// const LEARNING_API_URL = `${environment.learningBaseUrl}/learning`;
// const LEARNING_CREATE_API_URL = `${environment.learningBaseUrl}/learning/create`;
// const LEARNING_FEES_API_URL = `${environment.learningBaseUrl}/fees`

const LEARNER_URL = `${environment.learningBaseUrl}/learning/learners/`;
const LEARNER_EXPORT_URL = `${environment.learningBaseUrl}learning-program/learning-application-excel`;
const EXCEL_WITH_LEARNING_ID_URL = `${environment.learningBaseUrl}learning-application-excel-with-learning-id`;
const LEARNER_EXCEL_ID_URL = `${environment.learningBaseUrl}/learning/export-learners/`;
const LEARNER_EXCEL_ALL_URL = `${environment.learningBaseUrl}/applications/users`;

@Injectable({
  providedIn: 'root'
})
export class LearnerService {

  http: HttpClient = inject(HttpClient);

  public getAll(options: any): Observable<{ items: ApplicationModel[] }> {
    return this.http.get<{ items: ApplicationModel[] }>(LEARNER_URL).pipe(shareReplay(1));
  }

  public getListOfLearnersByLearningId(id: string): Observable<{ items: ApplicationModel[] }> {
    return this.http.get<{ items: ApplicationModel[] }>(LEARNER_URL + id)
  }

  public getLearnerExcelFileByLearningID(learningId: string, listType: 'osce' | 'msq') { //TODO: what is listType?
    return this.http.get(EXCEL_WITH_LEARNING_ID_URL, {
      params: {
        learningId: learningId,
        type: listType
      },
      responseType: 'blob'
    })
  }

  public getAllLearnersExcelFileByStatus(options: { type: string, ids: string[] }) {
    return this.http.post(LEARNER_EXPORT_URL, { ids: options.ids }, {
      params: {
        type: options.type,
      },
      responseType: 'blob'
    })
  }

  public exportLearnersById(learningId: string) {
    return this.http.get(LEARNER_EXCEL_ID_URL + learningId);
  }

  public exportAllLearners(payload: { type: string, take: number, skip: number }) {
    return this.http.get(`${LEARNER_EXCEL_ALL_URL}?take=${payload.take}&skip=${payload.skip}&examType=${payload.type}`);
  }

}
