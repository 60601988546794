import { inject, Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { map, Observable, tap } from "rxjs";
import { environment } from '@tsin-env/environment';
import { LearningType } from '@tsin-core/entities';
import { OrientationModel, OrientationSubmitRequest } from '@tsin-core/models/orientation.model';
import { UploadCSVModel } from '@tsin-core/models/upload-csv.model';

const ORIENTATION_API_URL_CREATE = `${environment.learningBaseUrl}/orientations`;
const ORIENTATION_API_URL_UPDATE = `${environment.learningBaseUrl}/orientations`;
const ORIENTATION_API_URL_DELETE = `${environment.learningBaseUrl}/orientations`;
const UPLOAD_CSV_API_URL_DELETE = `${environment.learningBaseUrl}/imports/single`;
const UPLOAD_CSV_HISTORY_URL = `${environment.learningBaseUrl}/imports`;
const ORIENTATION_API_URL_BY_TYPE = `${environment.learningBaseUrl}/orientations/type/`;
const ORIENTATION_API_URL_REASSIGN = `${environment.learningBaseUrl}learning-program/reassign-learner-to-orientation`;

@Injectable({
    providedIn: 'root'
})
export class UploadCSVService {

    http: HttpClient = inject(HttpClient);

    all(): Observable<UploadCSVModel[]> {
        return this.http.get<UploadCSVModel[]>(UPLOAD_CSV_HISTORY_URL, { params: { generate: Math.random() } })
            .pipe(map(response => response));
    }

    remove(id: string): Observable<any> {
        return this.http.delete(`${UPLOAD_CSV_API_URL_DELETE}/${id}`);
    }
}
