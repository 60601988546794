import {
  LoadingTrackManagementState,
  TrackManagementRequest,
} from '@tsin-core/models/track-management.model';

export class CreateTrack {
  static readonly type = '[Track] Add';
  constructor(public payload: Omit<TrackManagementRequest, 'id'>) {}
}

export class GetTrack {
  static readonly type = '[Track] Get';
}

export class GetSingleTrack {
  static readonly type = '[Track] Get One';
  constructor(public id: string) {}
}

export class DeleteSingleTrack {
  static readonly type = '[Track] Delete One';
  constructor(public id: string) {}
}

export class UpdateTrack {
  static readonly type = '[Track] Update';
  constructor(public payload: TrackManagementRequest) {}
}

export class GetNumberofCases {
  static readonly type = '[Track] Get';
  constructor(public id: number) {}
}

export class SetLoading {
  static readonly type = '[Track] Set Loading';
  constructor(public loading: LoadingTrackManagementState) {}
}

export class SetError {
  static readonly type = '[Track] Set Error';
  constructor(public error: string | null) {}
}
