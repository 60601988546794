import { inject, Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  AddCase,
  DeleteCase,
  GetCases,
  UpdateCase,
} from '@tsin-core/actions/case.action';
import { SetError, SetLoading } from '@tsin-core/actions/case.action';
import { CaseStateModel, LoadingCaseState } from '@tsin-core/models/case.model';
import { CaseService } from '@tsin-core/services/http/case.service';
import { catchError, of, tap } from 'rxjs';

@State<CaseStateModel>({
  name: 'caseState',
  defaults: {
    loading: LoadingCaseState.loadingList,
    cases: [],
    error: null,
  },
})
@Injectable()
export class CaseState {
  caseService: CaseService = inject(CaseService);

  @Selector()
  static getCase(state: CaseStateModel) {
    return state.cases;
  }

  @Selector()
  static getLoading(state: CaseStateModel) {
    return state.loading;
  }

  @Selector()
  static getError(state: CaseStateModel) {
    return state.error;
  }

  @Action(AddCase)
  addCase(ctx: StateContext<CaseStateModel>, action: AddCase) {
    ctx.patchState({ loading: LoadingCaseState.loadingAddUpdate, error: null });
    return this.caseService.createCases(action.payload).pipe(
      tap((result: any) => {
        const state = ctx.getState();
        ctx.patchState({
          cases: [...state.cases, result],
          loading: LoadingCaseState.notLoading,
        });
      }),
      catchError((error) => {
        ctx.patchState({
          loading: LoadingCaseState.notLoading,
          error: error.message,
        });
        throw error;
        // return of(error);
      })
    );
  }

  @Action(UpdateCase)
  updateCases(ctx: StateContext<CaseStateModel>, action: UpdateCase) {
    ctx.patchState({ loading: LoadingCaseState.loadingAddUpdate, error: null });
    return this.caseService.updateCases(action.payload).pipe(
      tap((result: any) => {

        const state = ctx.getState();

        const cases = [...state.cases];
        const caseIndex = cases.findIndex(item => item.id === action.payload.id);
        cases[caseIndex].name = result.name;
        cases[caseIndex].sessionId = result.sessionId;
        cases[caseIndex].description = result.description;
        ctx.patchState({ cases, loading: LoadingCaseState.notLoading, error: null });

        // ctx.patchState({
        //   cases: [...state.cases, result],
        //   loading: LoadingCaseState.notLoading,
        // });

      }),
      catchError((error) => {
        ctx.patchState({
          loading: LoadingCaseState.notLoading,
          error: error.message,
        });
        throw error;
        // return of(error);
      })
    );
  }

  @Action(DeleteCase)
  deleteCase(ctx: StateContext<CaseStateModel>, action: DeleteCase) {
    ctx.patchState({ loading: LoadingCaseState.loadingDelete });
    return this.caseService.deleteCase(action.id).pipe(
      tap((result: any) => {
        const state = ctx.getState();
        ctx.patchState({
          cases: state.cases.filter(c => c.id != action.id),
          loading: LoadingCaseState.notLoading,
        });
      }),
      catchError((error) => {
        ctx.patchState({
          loading: LoadingCaseState.notLoading,
          error: error.message,
        });
        // return of(error);
        throw error;
      })
    );
  }

  @Action(GetCases)
  getCases(ctx: StateContext<CaseStateModel>) {
    ctx.patchState({ loading: LoadingCaseState.loadingList });
    return this.caseService.getCases().pipe(
      tap((cases: any) => {
        ctx.patchState({
          cases: cases.data,
          loading: LoadingCaseState.notLoading,
        });
      }),
      catchError((error) => {
        ctx.patchState({
          loading: LoadingCaseState.notLoading,
          error: error.message,
        });
        return of(error);
      })
    );
  }

  @Action(SetLoading)
  setLoading(ctx: StateContext<CaseStateModel>, action: SetLoading) {
    ctx.patchState({ loading: action.loading });
  }

  @Action(SetError)
  setError(ctx: StateContext<CaseStateModel>, action: SetError) {
    ctx.patchState({ error: action.error });
  }
}
