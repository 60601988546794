export type AttendanceStatus = "Present" | "Absent" | "AWOL"

export interface AttendanceStateModel {
  attendances: AttendanceModel[];
  loading: LoadingAttendanceState;
  error: any | null;
}

export interface AttendanceRequest {
  id: string;
  attendance: AttendanceStatus;
  comment?: string;
}

export interface AttendanceScheduleRequest {
  weeklyScheduleId: string;
  sessionId: string;
}

export interface AsynchronousActivityRequest {
  id: string,
  asynchronousActivity: {
    sensitiveClinicalExam: "Complete" | "Not Completed",
    obstetricsInFM: "Complete" | "Not Completed",
    onCallSkillsInFM: "Complete" | "Not Completed",
    palliativeEndOfLifeCare: "Complete" | "Not Completed",
    periodicHealthExam: "Complete" | "Not Completed"
  }
}

export interface AttendanceModel {
  id: string,
  sessionId: string,
  weeklyScheduleId: string,
  learningId: string,
  userId: string,
  userName: string,
  PGYLevel: string | null,
  track: string,
  group: string,
  applicationId: string,
  associatedMedicalSchool: string,
  postgraduateTrainingProgram: string,
  attendance: AttendanceStatus,
  asynchronousActivities: any[],
  comment: string,
  totalPresent: number,
  totalSession: number
}

export enum LoadingAttendanceState {
  loadingList = 'loadingList',
  loadingAddUpdate = 'loadingAddUpdate',
  loadingDelete = 'loadingDelete',
  notLoading = 'notLoading',
}
