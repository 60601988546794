import { inject, Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { AddOrientation, DeleteOrientation, GetAvailableOrientations, GetOrientations, SetOrientationError, SetOrientationLoading, UpdateOrientation, UpdateOrientationStatus } from "@tsin-core/actions/orientation.action";
import { DeleteUploadCSV, GetUploadCSVs, SetUploadCSVError, SetUploadCSVLoading } from "@tsin-core/actions/upload-csv.action";
import { LoadingUploadCSVState, UploadCSVModel, UploadCSVStateModel } from "@tsin-core/models/upload-csv.model";
import { OrientationAdminService } from "@tsin-core/services/http/orientation-admin.service";
import { UploadCSVService } from "@tsin-core/services/http/upload-csv.service";
import { catchError, of, tap } from "rxjs";

@State<UploadCSVStateModel>({
    name: 'uploadCSVState',
    defaults: {
        loading: LoadingUploadCSVState.loadingList,
        history: [],
        error: null,
    }
})

@Injectable()
export class UploadCSVState {
    uploadCSVService: UploadCSVService = inject(UploadCSVService);

    @Selector()
    static getCSVHistory(state: UploadCSVStateModel) {
        return state.history;
    }

    @Selector()
    static getLoading(state: UploadCSVStateModel) {
        return state.loading;
    }

    @Selector()
    static getError(state: UploadCSVStateModel) {
        return state.error;
    }


    @Action(DeleteUploadCSV)
    deleteUploadCSV(ctx: StateContext<UploadCSVStateModel>, action: DeleteUploadCSV) {
        ctx.patchState({ loading: LoadingUploadCSVState.loadingDelete });
        return this.uploadCSVService.remove(action.id).pipe(
            tap(() => {
                const state = ctx.getState();
                const filteredHistory = state.history.filter(his => his.id !== action.id);
                ctx.patchState({ history: filteredHistory, loading: LoadingUploadCSVState.notLoading });
            }),
            catchError((error) => {
                ctx.patchState({ loading: LoadingUploadCSVState.notLoading, error: error.message });
                return of(error);
            })
        );
    }

    @Action(GetUploadCSVs)
    getHistoryCSV(ctx: StateContext<UploadCSVStateModel>, action: GetUploadCSVs) {
        ctx.patchState({ loading: LoadingUploadCSVState.loadingList });
        return this.uploadCSVService.all().pipe(
            tap((history: any) => {
                console.log('History:::', history);
                ctx.patchState({ history: history, loading: LoadingUploadCSVState.notLoading });
            }),
            catchError((error) => {
                ctx.patchState({ loading: LoadingUploadCSVState.notLoading, error: error.message });
                return of(error);
            })
        );
    }

    @Action(SetUploadCSVLoading)
    setLoading(ctx: StateContext<UploadCSVStateModel>, action: SetUploadCSVLoading) {
        ctx.patchState({ loading: action.loading });
    }

    @Action(SetUploadCSVError)
    setError(ctx: StateContext<UploadCSVStateModel>, action: SetUploadCSVError) {
        ctx.patchState({ error: action.error });
    }
}