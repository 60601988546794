import {
  AttendanceRequest,
  AttendanceScheduleRequest,
  LoadingAttendanceState,
} from '@tsin-core/models/attendance.model';

export class UpdateAttendance {
  static readonly type = '[Attendance] Update';
  constructor(public payload: AttendanceRequest) { }
}

export class GetAttendanceByScheduleSession {
  static readonly type = '[Attendance] Get Attendance By Schedule and Session';
  constructor(public payload: AttendanceScheduleRequest) { }
}

export class SetLoading {
  static readonly type = '[Attendance] Set Loading';
  constructor(public loading: LoadingAttendanceState) { }
}

export class SetError {
  static readonly type = '[Attendance] Set Error';
  constructor(public error: string | null) { }
}
