
export interface SessionStateModel {
  sessions:SessionResponse[] ;
  loading: LoadingSessionState;
  error: any | null;
}

export interface SessionResponse {
  id: string
  name: string
  isActive: boolean
  learningId: string
  createdAt?: string
  updatedAt?: string
}
export interface SessionRequest { 
  name: string
  id?: string 
}

export enum LoadingSessionState {
  loadingList = "loadingList",
  loadingAddUpdate = "loadingAddUpdate",
  loadingDelete = "loadingDelete",
  notLoading = "notLoading",
}