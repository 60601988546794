import {
  LoadingScheduleState,
  ScheduleByIdSessionRequest,
  ScheduleRequest,
  WeeklyScheduleRequest,
} from '@tsin-core/models/schedule.model';

export class CreateSchedule {
  static readonly type = '[Schedule] Create';
  constructor(public payload: Omit<ScheduleRequest, 'id'>) { }
}

export class CreateWeeklySchedule {
  static readonly type = '[Schedule] Create Weekly';
  constructor(public payload: Omit<WeeklyScheduleRequest, 'id'>) { }
}

export class GetSchedulesByLearning {
  static readonly type = '[Schedule] Get';
  constructor(public learningId: string) { }
}

export class GetWeeklySchedules{
  static readonly type = '[Schedule] Get Weekly Schedule';
}

export class GetScheduleByIdSession{
  static readonly type = '[Schedule] By Id and Session';
  constructor(public payload: ScheduleByIdSessionRequest) { }
}

export class GetSingleSchedules {
  static readonly type = '[Schedule] Get Single';
}

export class SetLoading {
  static readonly type = '[Schedule] Set Loading';
  constructor(public loading: LoadingScheduleState) { }
}

export class SetError {
  static readonly type = '[Schedule] Set Error';
  constructor(public error: string | null) { }
}
