import { inject, Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { AddApplication, CheckUserRegistrations, DisableForm, GetLearnersByLearning, GetUserApplication, GetUserApplicationByLearner, ReassignLearners, SetApplicationError, SetApplicationLoading, SetSingleApplication, ShowErrorMessage, UpdateApplication, UpdateLearningLearnerStatus } from "@tsin-core/actions/application.action";
import { SetLearningError, SetLearningLoading } from "@tsin-core/actions/learning.action";
import { ApplicationModel, ApplicationStateModel, LoadingApplicationState } from "@tsin-core/models/application.model";
import { LearningStateModel, LoadingLearningState } from "@tsin-core/models/learning.model";
import { ApplicationService } from "@tsin-core/services/http/application.service";
import { LearningService } from "@tsin-core/services/http/learning.service";
import { UserService } from "@tsin-core/services/http/user.service";
import { catchError, of, tap } from "rxjs";

@State<ApplicationStateModel>({
    name: 'applicationState',
    defaults: {
        loading: LoadingApplicationState.loadingList,
        applications: [],
        selectApplication: undefined,
        isUserHasRegistrations: null,
        error: null,
    }
})

@Injectable()
export class ApplicationState {

    userService: UserService = inject(UserService);
    applicationService: ApplicationService = inject(ApplicationService);
    learningService: LearningService = inject(LearningService);

    @Selector()
    static getApplicationPrograms(state: ApplicationStateModel) {
        return state.applications;
    }

    @Selector()
    static getCurrentApplication(state: ApplicationStateModel) {
        return state.selectApplication;
    }

    @Selector()
    static getLoading(state: ApplicationStateModel) {
        return state.loading;
    }

    @Selector()
    static getError(state: ApplicationStateModel) {
        return state.error;
    }

    @Action(GetUserApplicationByLearner)
    getApplicationByLearner(ctx: StateContext<ApplicationStateModel>, action: GetUserApplicationByLearner) {
        return this.applicationService.getApplicationByLearner(action.learningType).pipe(
            tap((res: any) => {
                ctx.patchState({ applications: res.data, selectApplication: res.data[0] });
            }),
            catchError((err) => {
                ctx.dispatch(new ShowErrorMessage()); // Dispatch action to show error
                return of(err);
            })
        );
    }

    @Action(GetUserApplication)
    getApplication(ctx: StateContext<ApplicationStateModel>, action: GetUserApplication) {
        return this.applicationService.getSingleApplication(action.payload).pipe(
            tap((res: any) => {
                ctx.patchState({ selectApplication: res.data });
            }),
            catchError((err) => {
                ctx.dispatch(new ShowErrorMessage()); // Dispatch action to show error
                return of(err);
            })
        );
    }

    @Action(GetLearnersByLearning)
    getLearnersByLearning(ctx: StateContext<ApplicationStateModel>, action: GetLearnersByLearning) {
        ctx.patchState({ loading: LoadingApplicationState.loadingList, error: null });
        return this.learningService.getLearnersByLearningID(action.learningId).pipe(
            tap((learners: any) => {
                console.log('ALL Learners in Program::', learners);
                ctx.patchState({ applications: learners, loading: LoadingApplicationState.notLoading });
            }),
            catchError((error) => {
                ctx.patchState({ loading: LoadingApplicationState.notLoading, error: error.message });
                return of(error);
            })
        );
    }


    @Action(CheckUserRegistrations)
    checkUserRegistrations(ctx: StateContext<ApplicationStateModel>, action: CheckUserRegistrations) {
        return this.applicationService.checkIsUserHasRegistrationsToType(action.learningType).pipe(
            tap((res) => {
                const isRegistered = res.code.registered;
                ctx.patchState({ isUserHasRegistrations: isRegistered });
                if (isRegistered) {
                    ctx.dispatch(new DisableForm());
                    // Dispatch action to disable the form
                }
            }),
            catchError((err) => {
                ctx.dispatch(new ShowErrorMessage());
                // Dispatch action to show error
                return of(err);
            })
        );
    }

    @Selector()
    static isUserHasRegistrations(state: ApplicationStateModel) {
        return !!state.selectApplication;
    }

    @Action(AddApplication)
    addApplicationProgram(ctx: StateContext<ApplicationStateModel>, action: AddApplication) {
        ctx.patchState({ loading: LoadingApplicationState.loadingAddUpdate, error: null });
        return this.applicationService.addApplication(action.payload).pipe(
            tap((result: any) => {
                const state = ctx.getState();
                ctx.patchState({
                    selectApplication: result.data,
                    loading: LoadingApplicationState.notLoading
                });
            }),
            catchError((error) => {
                let errorMessage = error.statusText;
                if (error.error.message && Array.isArray(error.error.message)) {
                    errorMessage = error.error.message.join(', \n\n\r\t');
                } else if (error.error.message) {
                    errorMessage = error.error.message;
                }
                ctx.patchState({ loading: LoadingApplicationState.notLoading, error: errorMessage });
                return of(error);
            })
        );
    }

    
    @Action(ReassignLearners)
    reassignLearners(ctx: StateContext<ApplicationStateModel>, action: ReassignLearners) {
        ctx.patchState({ loading: LoadingApplicationState.loadingAddUpdate, error: null });
        return this.applicationService.reassignLearners(action.payload).pipe(
            tap((result: any) => {
                console.log('Learners Reassigned:::', result);
                const state = ctx.getState();
                ctx.patchState({ loading: LoadingApplicationState.notLoading });
            }),
            catchError((error) => {
                ctx.patchState({ loading: LoadingApplicationState.notLoading, error: error.error.message ?? error.error.error });
                return of(error);
            })
        );
    }


    // UpdateLearningLearnerStatus{
    //     static readonly type = '[Application] Update Learning Learner Status';
    //     constructor(public payload: ApplicationStatusUpdateRequest

    @Action(UpdateLearningLearnerStatus)
    updateLearnersStatus(ctx: StateContext<ApplicationStateModel>, action: UpdateLearningLearnerStatus) {
        ctx.patchState({ loading: LoadingApplicationState.loadingAddUpdate });
        return this.applicationService.updateLearnersStatus(action.payload).pipe(
            tap((result: any) => {
                const state = ctx.getState();
                console.log('Update Learners Status', result);
                // const learningPrograms = [...state.learningPrograms];
                // const learningProgramIndex = learningPrograms.findIndex(item => item.id === action.payload.id);
                // learningPrograms[learningProgramIndex] = { ...learningPrograms[learningProgramIndex], ...result.data };
                ctx.patchState({ loading: LoadingApplicationState.notLoading });
            }),
            catchError((error) => {
                ctx.patchState({ loading: LoadingApplicationState.notLoading, error: error.message });
                return of(error);
            })
        );
    }

    @Action(SetApplicationLoading)
    setLoading(ctx: StateContext<ApplicationStateModel>, action: SetApplicationLoading) {
        ctx.patchState({ loading: action.loading });
    }

    @Action(SetSingleApplication)
    setSingleApplication(ctx: StateContext<ApplicationStateModel>, action: SetSingleApplication) {
        ctx.patchState({ selectApplication: action.payload });
    }

    @Action(SetApplicationError)
    setError(ctx: StateContext<ApplicationStateModel>, action: SetApplicationError) {
        ctx.patchState({ error: action.error });
    }
}