import { state } from "@angular/animations";
import { inject, Injectable } from "@angular/core";
import { Action, select, Selector, State, StateContext } from "@ngxs/store";
import { AddSchool, DeleteSchool, GetSchool, GetSchools, SelectSchool, SetSchoolsError, SetSchoolsLoading, UpdateSchool } from "@tsin-core/actions/schools.action";
import { LoadingSchoolsState, SchoolsStateModel } from "@tsin-core/models/schools.model";
import { SchoolsService } from "@tsin-core/services/http/schools.service";
import { catchError, of, tap } from "rxjs";


@State<SchoolsStateModel>({
    name: 'schoolsState',
    defaults: {
        loading: LoadingSchoolsState.loadingList,
        selectedSchool: null,
        schools: [],
        error: null,
    }
})

@Injectable()
export class SchoolsState {
    schoolsService: SchoolsService = inject(SchoolsService);

    @Selector()
    static getSchools(state: SchoolsStateModel) {
        return state.schools;
    }

    @Selector()
    static getSelectedResult(state: SchoolsStateModel) {
        return state.selectedSchool;
    }


    @Selector()
    static getLoading(state: SchoolsStateModel) {
        return state.loading;
    }

    @Selector()
    static getError(state: SchoolsStateModel) {
        return state.error
    }


    @Action(SelectSchool)
    selectEntity(ctx: StateContext<SchoolsStateModel>, action: SelectSchool) {
        const state = ctx.getState();
        const selected = state.schools.find(entity => entity.id === action.id);
        ctx.patchState({
            selectedSchool: selected
        });
    }


    @Action(GetSchools)
    getAllSchools(ctx: StateContext<SchoolsStateModel>, action: GetSchools) {
        ctx.patchState({ loading: LoadingSchoolsState.loadingList });
        return this.schoolsService.getSchools().pipe(
            tap((schools: any) => {
                const state = ctx.getState();
                console.log('All Schools', schools);
                ctx.patchState({
                    schools: schools,
                    loading: LoadingSchoolsState.notLoading
                })
            }),
            catchError((error) => {
                ctx.patchState({
                    loading: LoadingSchoolsState.notLoading,
                    error: error.message
                });
                throw error;
            })
        )
    }

    @Action(UpdateSchool)
    updateSchool(ctx: StateContext<SchoolsStateModel>, action: UpdateSchool) {
        ctx.patchState({ loading: LoadingSchoolsState.loadingAddUpdate });
        return this.schoolsService.updateSchool(action.payload).pipe(
            tap((result: any) => {
                const state = ctx.getState();
                const schools = [...state.schools];
                const index = schools.findIndex(item => item.id === action.payload.id);
                schools[index].name = result.name;
                ctx.patchState({ schools, loading: LoadingSchoolsState.notLoading, error: null });
            }),
            catchError((error) => {
                ctx.patchState({
                    loading: LoadingSchoolsState.notLoading,
                    error: error.message
                });
                throw error;
            })
        )
    }

    @Action(GetSchool)
    getSingleSchools(ctx: StateContext<SchoolsStateModel>, action: GetSchool) {
        ctx.patchState({ loading: LoadingSchoolsState.loadingList });
        return this.schoolsService.getSingleSchool(action.schoolId).pipe(
            tap((schoolRes: any) => {
                ctx.patchState({
                    selectedSchool: schoolRes.data,
                    loading: LoadingSchoolsState.notLoading
                })
            }),
            catchError((error) => {
                ctx.patchState({
                    loading: LoadingSchoolsState.notLoading,
                    error: error.message
                });
                throw error;
            })
        )
    }

    @Action(AddSchool)
    addSchool(ctx: StateContext<SchoolsStateModel>, action: AddSchool) {
        ctx.patchState({ loading: LoadingSchoolsState.loadingAddUpdate });
        return this.schoolsService.addSchool(action.payload).pipe(
            tap((school: any) => {
                const state = ctx.getState();

                ctx.patchState({
                    schools: [...state.schools, school],
                    loading: LoadingSchoolsState.notLoading,
                });
            }),
            catchError((error) => {
                ctx.patchState({
                    loading: LoadingSchoolsState.notLoading,
                    error: error.message
                });
                throw error;
            })
        )
    }

    @Action(DeleteSchool)
    deleteSchool(ctx: StateContext<SchoolsStateModel>, action: DeleteSchool) {
      ctx.patchState({ loading: LoadingSchoolsState.loadingDelete });
      return this.schoolsService.deleteSingleSchool(action.schoolId).pipe(
        tap((result: any) => {
          const state = ctx.getState();
          ctx.patchState({
            schools: state.schools.filter(c => c.id != action.schoolId),
            loading: LoadingSchoolsState.notLoading,
          });
        }),
        catchError((error) => {
          ctx.patchState({
            loading: LoadingSchoolsState.notLoading,
            error: error.message,
          }); 
          throw error;
        })
      );
    }


    @Action(SetSchoolsLoading)
    setLoading(ctx: StateContext<SchoolsStateModel>, action: SetSchoolsLoading) {
        ctx.patchState({ loading: action.loading });
    }

    @Action(SetSchoolsError)
    setError(ctx: StateContext<SchoolsStateModel>, action: SetSchoolsError) {
        ctx.patchState({ error: action.error });
    }

}