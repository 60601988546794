import { CaseRequest, LoadingCaseState } from "@tsin-core/models/case.model";

export class AddCase {
  static readonly type = '[Case] Add';
  constructor(public payload: Omit<CaseRequest, 'id'>) { }
}

export class UpdateCase {
  static readonly type = '[Case] Update';
  constructor(public payload: CaseRequest) { }
}

export class DeleteCase {
  static readonly type = '[Case] Delete';
  constructor(public id: string) { }
}

export class GetCases {
  static readonly type = '[Case] Get';
}

export class GetCase {
  static readonly type = '[Case] Get One';
  constructor(public id: string) { }
}

export class SetLoading {
  static readonly type = '[Case] Set Loading';
  constructor(public loading: LoadingCaseState) { }
}

export class SetError {
  static readonly type = '[Case] Set Error';
  constructor(public error: string | null) { }
}
