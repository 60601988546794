import { LoadingResultsState, ResultBySchoolRequest, SingleResultRequest } from "@tsin-core/models/results.model";

export class ReleaseAllResults {
    static readonly type = '[Results] Release All Results';
}

export class ReleaseSingleResult {
    static readonly type = '[Results] Release Single Result';
    constructor(public payload: SingleResultRequest) { }
}

export class GetResults {
    static readonly type = '[Results] Get Results';
    // constructor(public payload: LearningSubmitRequest) { }
}

export class GetResultsBySchool {
    static readonly type = '[Results] Get Results by School';
    constructor(public payload: ResultBySchoolRequest) { }
}

export class SelectResult {
    static readonly type = '[Results] Select Result';
    constructor(public id: string) { }
}

export class SetResultsLoading {
    static readonly type = '[Results] Set Results Loading';
    constructor(public loading: LoadingResultsState) { }
}

export class SetResultsError {
    static readonly type = '[Results] Set Results Error';
    constructor(public error: string | null) { }
}
