import { inject, Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  CreateTrack,
  DeleteSingleTrack,
  GetNumberofCases,
  GetSingleTrack,
  GetTrack,
  SetError,
  SetLoading,
  UpdateTrack,
} from '@tsin-core/actions/track-management.action';
import {
  LoadingTrackManagementState,
  TrackManagementStateModel,
} from '@tsin-core/models/track-management.model';
import { TrackManagementService } from '@tsin-core/services/http/track-management.service';
import { catchError, of, tap } from 'rxjs';

@State<TrackManagementStateModel>({
  name: 'trackManagementState',
  defaults: {
    loading: LoadingTrackManagementState.loadingList,
    trackManagement: [],
    error: null,
  },
})
@Injectable()
export class TrackManagementState {
  trackManagementService: TrackManagementService = inject(
    TrackManagementService
  );

  @Selector()
  static getTrackManagement(state: TrackManagementStateModel) {
    return state.trackManagement;
  }

  @Selector()
  static getLoading(state: TrackManagementStateModel) {
    return state.loading;
  }

  @Selector()
  static getError(state: TrackManagementStateModel) {
    return state.error;
  }

  @Action(CreateTrack)
  createTask(
    ctx: StateContext<TrackManagementStateModel>,
    action: CreateTrack
  ) {
    ctx.patchState({ loading: LoadingTrackManagementState.loadingAddUpdate });

    return this.trackManagementService.createTask(action.payload).pipe(
      tap((result: any) => {
        const state = ctx.getState();
        ctx.patchState({
          trackManagement: [...state.trackManagement, result],
          loading: LoadingTrackManagementState.notLoading,
        });
      }),
      catchError((error) => {
        ctx.patchState({
          loading: LoadingTrackManagementState.notLoading,
          error: error.message,
        });
        return of(error);
      })
    );
  }

// UPDATE TRACK
  @Action(UpdateTrack)
  updateTrack(
    ctx: StateContext<TrackManagementStateModel>,
    action: UpdateTrack
  ) {
    ctx.patchState({ loading: LoadingTrackManagementState.loadingList });

    return this.trackManagementService.updateTrack(action.payload).pipe(
      tap((result: any) => {
        const state = ctx.getState();
        const updatedTrackManagement = state.trackManagement.map(track =>
          track.id === action.payload.id ? result : track
        );
  
        ctx.patchState({
          trackManagement: updatedTrackManagement,
          loading: LoadingTrackManagementState.notLoading,
        });
      }),
      catchError((error) => {
        ctx.patchState({
          loading: LoadingTrackManagementState.notLoading,
          error: error.message,
        });
        return of(error);
      })
    );
  }

// GET NUMBER OF CASES
  @Action(GetNumberofCases)
  numberofCases(
    ctx: StateContext<TrackManagementStateModel>,
    action: GetNumberofCases
  ) {
    ctx.patchState({ loading: LoadingTrackManagementState.loadingList });

    return this.trackManagementService.numberofCases(action.id).pipe(
      tap((result: any) => {
        const state = ctx.getState();
        ctx.patchState({
          trackManagement: [...state.trackManagement, result],
          loading: LoadingTrackManagementState.notLoading,
        });
      }),
      catchError((error) => {
        ctx.patchState({
          loading: LoadingTrackManagementState.notLoading,
          error: error.message,
        });
        return of(error);
      })
    );
  }

  // GET SINGLE TRACK
  @Action(GetSingleTrack)
  getSingleTrack(
    ctx: StateContext<TrackManagementStateModel>,
    action: GetSingleTrack
  ) {
    ctx.patchState({ loading: LoadingTrackManagementState.loadingList });

    return this.trackManagementService.getSingleTrack(action.id).pipe(
      tap((tracks: any) => {
        ctx.patchState({
          trackManagement: tracks.data,
          loading: LoadingTrackManagementState.notLoading,
        });
      }),
      catchError((error) => {
        ctx.patchState({
          loading: LoadingTrackManagementState.notLoading,
          error: error.message,
        });
        return of(error);
      })
    );
  }

  // DELETE SINGLE TRACK
  @Action(DeleteSingleTrack)
  deleteSingleTrack(
    ctx: StateContext<TrackManagementStateModel>,
    action: GetSingleTrack
  ) {
    ctx.patchState({ loading: LoadingTrackManagementState.loadingList });

    return this.trackManagementService.deleteSingleTrack(action.id).pipe(
      tap((tracks: any) => {
        const state = ctx.getState();
        ctx.patchState({
          trackManagement: state.trackManagement.filter(track => track.id !== action.id),
          loading: LoadingTrackManagementState.notLoading,
        });
      }),
      catchError((error) => {
        ctx.patchState({
          loading: LoadingTrackManagementState.notLoading,
          error: error.message,
        });
        return of(error);
      })
    );
  }

  @Action(GetTrack)
  getTasks(ctx: StateContext<TrackManagementStateModel>, action: GetTrack) {
    ctx.patchState({ loading: LoadingTrackManagementState.loadingList });

    return this.trackManagementService.getTasks().pipe(
      tap((tracks: any) => {
        ctx.patchState({
          trackManagement: tracks.data,
          loading: LoadingTrackManagementState.notLoading,
        });
      }),
      catchError((error) => {
        ctx.patchState({
          loading: LoadingTrackManagementState.notLoading,
          error: error.message,
        });
        return of(error);
      })
    );
  }

  @Action(SetLoading)
  setLoading(ctx: StateContext<TrackManagementStateModel>, action: SetLoading) {
    ctx.patchState({ loading: action.loading });
  }

  @Action(SetError)
  setError(ctx: StateContext<TrackManagementStateModel>, action: SetError) {
    ctx.patchState({ error: action.error });
  }
}
