import { Routes } from '@angular/router';
import { authGuard } from '@tsin-core/guards/auth.guard';

export const routes: Routes = [
  { path: '', redirectTo: '/account/validate-login', pathMatch: 'full' },
  { path: 'user', canActivate: [authGuard], loadChildren: () => import('./pages/user/user.routes') },
  { path: 'facilitator', canActivate: [authGuard], data: { preload: true }, loadChildren: () => import('./pages/facilitator/facilitator.routes') },
  { path: 'university', canActivate: [authGuard], data: { preload: true }, loadChildren: () => import('./pages/facilitator/facilitator.routes') },
  { path: 'management', canActivate: [authGuard], data: { preload: true }, loadChildren: () => import('./pages/management/management.routes') },
  { path: 'account', data: { preload: true }, loadChildren: () => import('./pages/account/account.routes') },
  { path: '**', redirectTo: '/account/validate-login' }
];