import { LearningType } from "@tsin-core/entities";
import { LearningFeeSubmitRequest, LearningListRequest, LearningSubmitRequest, LoadingLearningState } from "@tsin-core/models/learning.model";
import { LoadingOrientationState, OrientationListRequest, OrientationSubmitRequest } from "@tsin-core/models/orientation.model";

export class AddOrientation {
    static readonly type = '[Orientation] Add';
    constructor(public payload: Omit<OrientationSubmitRequest, 'id'>) { }
}

export class UpdateOrientation {
    static readonly type = '[Orientation] Update';
    constructor(public payload: OrientationSubmitRequest) { }
}

export class UpdateOrientationStatus {
    static readonly type = '[Orientation] Update Status';
    constructor(public payload: any) { }
}

export class ResetOrientationStore {
    static readonly type = '[Orientation] Reset';
    constructor() { }
}

export class DeleteOrientation {
    static readonly type = '[Orientation] Delete';
    constructor(public id: string) { }
}

export class GetOrientations {
    static readonly type = '[Orientation] Get';
    constructor(public payload: OrientationListRequest) { }
}

export class GetAvailableOrientations {
    static readonly type = '[Orientation] Get Available';
    constructor(public type: LearningType) { }
}

export class GetOrientation {
    static readonly type = '[Orientation] Get One';
    constructor(public id: string) { }
}

export class SetOrientationLoading {
    static readonly type = '[Orientation] Set Loading';
    constructor(public loading: LoadingOrientationState) { }
}

export class SetOrientationError {
    static readonly type = '[Orientation] Set Error';
    constructor(public error: string | null) { }
}
