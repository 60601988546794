import { inject, Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { map, Observable, tap } from "rxjs";
import { environment } from '@tsin-env/environment';
import { LearningType } from '@tsin-core/entities';
import { OrientationListRequest, OrientationModel, OrientationSubmitRequest } from '@tsin-core/models/orientation.model';

const ORIENTATION_API_URL_CREATE = `${environment.learningBaseUrl}/orientations`;
const ORIENTATION_API_URL_UPDATE = `${environment.learningBaseUrl}/orientations`;
const ORIENTATION_API_URL_DELETE = `${environment.learningBaseUrl}/orientations`;
const ORIENTATION_API_URL_ALL = `${environment.learningBaseUrl}/orientations`;
const ORIENTATION_API_URL_BY_TYPE = `${environment.learningBaseUrl}/orientations/type/`;
const ORIENTATION_API_URL_REASSIGN = `${environment.learningBaseUrl}learning-program/reassign-learner-to-orientation`;

@Injectable({
  providedIn: 'root'
})
export class OrientationAdminService {

  http: HttpClient = inject(HttpClient);

  create(orientation: Omit<OrientationSubmitRequest, 'id'>): Observable<any> {
    return this.http.post(ORIENTATION_API_URL_CREATE, orientation);
  }

  all(payload: OrientationListRequest): Observable<OrientationModel[]> {
    return this.http.get<{ data: OrientationModel[] }>(ORIENTATION_API_URL_ALL, { params: { time: payload.time, type: payload.type } })
      .pipe(map(response => response.data));
  }

  getAvailableOrientations(type: LearningType, time: string): Observable<OrientationModel[]> {
    return this.getByType(type, time).pipe(
      map((orientations: OrientationModel[]) => orientations.filter((orientation: any) => orientation.isActive),
      ));
  }

  getByType(type: LearningType, time: string): Observable<OrientationModel[]> {
    return this.http.get<{ data: OrientationModel[] }>(ORIENTATION_API_URL_BY_TYPE + type, { params: { time } })
      .pipe(
        tap(response => console.log(response.data)),
        map(response => response.data)
      );
  }

  getById(id: string): Observable<OrientationModel> {
    return this.http.get<OrientationModel>(`${ORIENTATION_API_URL_ALL}/${id}`);
  }

  update(id: string, orientation: Partial<OrientationSubmitRequest>): Observable<any> {
    return this.http.put(ORIENTATION_API_URL_UPDATE, orientation);
  }

  remove(id: string): Observable<any> {
    return this.http.delete(`${ORIENTATION_API_URL_DELETE}/id/${id}`);
  }

  updateStatus(id: string, isActive: boolean) {
    return this.update(id, { isActive });
  }

  updateOrientationStatus(data: { id: string, activate: boolean }) {
    return this.http.patch(`${ORIENTATION_API_URL_UPDATE}`, data);
  }

  reassign(orientationId: string, userIds: string[]) {
    return this.http.post(`${ORIENTATION_API_URL_REASSIGN}`, { orientationId, userIds });
  }
}
