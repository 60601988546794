import { inject, Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { UpdateAttendance, GetAttendanceByScheduleSession, SetError, SetLoading } from '@tsin-core/actions/attendance.action';
import { LoadingAssessmentState } from '@tsin-core/models/assessment.model';
import {
  AttendanceModel,
  AttendanceStateModel,
  LoadingAttendanceState,
} from '@tsin-core/models/attendance.model';
import { AttendanceService } from '@tsin-core/services/http/attendance.service';
import { tap, catchError, of } from 'rxjs';

@State<AttendanceStateModel>({
  name: 'attendanceState',
  defaults: {
    loading: LoadingAttendanceState.loadingList,
    attendances: [],
    error: null,
  },
})
@Injectable()
export class AttendanceState {
  attendanceService: AttendanceService = inject(AttendanceService);

  @Selector()
  static getAttendances(state: AttendanceStateModel) {
    return state.attendances;
  }

  @Selector()
  static getLoading(state: AttendanceStateModel) {
    return state.loading;
  }

  @Selector()
  static getError(state: AttendanceStateModel) {
    return state.error;
  }

  @Action(UpdateAttendance)
  updateAttendance(ctx: StateContext<AttendanceStateModel>, action: UpdateAttendance){
    // ctx.patchState({loading: LoadingAttendanceState.loadingList});
    return this.attendanceService.updateAttendanceStatus(action.payload).pipe(
      tap((result: any) => {
        const state = ctx.getState();
        console.log(result);
        ctx.patchState({
          attendances:  [...state.attendances, result] ,
          loading: LoadingAttendanceState.notLoading
        })
      }),
      catchError((error) => {
        ctx.patchState({
          loading: LoadingAttendanceState.notLoading,
          error: error.message
        });
        return of(error)
      })
    )
  }

  @Action(GetAttendanceByScheduleSession)
  getAttendance(ctx: StateContext<AttendanceStateModel>, action: GetAttendanceByScheduleSession) {
    ctx.patchState({ loading: LoadingAttendanceState.loadingList });

    return this.attendanceService.getAttendanceBySchedule( action.payload ).pipe(
      tap((results: AttendanceModel[]) => {
        ctx.patchState({
          attendances:  results,
          loading: LoadingAttendanceState.notLoading
        });
      }),
      catchError((error) => {
        ctx.patchState({
          loading: LoadingAttendanceState.notLoading,
          error: error.message,
        });
        return of(error);
      })
    );
  }

  @Action(SetLoading)
  setLoading(ctx: StateContext<AttendanceStateModel>, action: SetLoading) {
    ctx.patchState({ loading: action.loading });
  }

  @Action(SetError)
  setError(ctx: StateContext<AttendanceStateModel>, action: SetError) {
    ctx.patchState({ error: action.error });
  }


}
