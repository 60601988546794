import { inject, Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { AddOrientation, DeleteOrientation, GetAvailableOrientations, GetOrientations, ResetOrientationStore, SetOrientationError, SetOrientationLoading, UpdateOrientation, UpdateOrientationStatus } from "@tsin-core/actions/orientation.action";
import { LoadingOrientationState, OrientationStateModel } from "@tsin-core/models/orientation.model";
import { OrientationAdminService } from "@tsin-core/services/http/orientation-admin.service";
import { catchError, of, tap } from "rxjs";

@State<OrientationStateModel>({
    name: 'orientationState',
    defaults: {
        loading: LoadingOrientationState.loadingList,
        orientations: [],
        error: null,
    }
})

@Injectable()
export class OrientationState {
    orientationService: OrientationAdminService = inject(OrientationAdminService);

    @Selector()
    static getOrientations(state: OrientationStateModel) {
        return state.orientations;
    }

    @Selector()
    static getLoading(state: OrientationStateModel) {
        return state.loading;
    }

    @Selector()
    static getError(state: OrientationStateModel) {
        return state.error;
    }

    @Action(ResetOrientationStore)
    resetOrientationStore(ctx: StateContext<OrientationStateModel>, action: ResetOrientationStore) {
        ctx.patchState({ orientations: undefined });
    }
    @Action(AddOrientation)
    addOrientation(ctx: StateContext<OrientationStateModel>, action: AddOrientation) {
        ctx.patchState({ loading: LoadingOrientationState.loadingAddUpdate, error: null });
        return this.orientationService.create(action.payload).pipe(
            tap((result: any) => {
                const state = ctx.getState();
                console.log(result.data);
                console.log(state.orientations);
                ctx.patchState({
                    orientations: [...state.orientations, result.data],
                    loading: LoadingOrientationState.notLoading
                });
            }),
            catchError((error) => {
                let errorMessage = error.statusText;
                if (error.error.message && Array.isArray(error.error.message)) {
                    errorMessage = error.error.message.join(', \n\n\r\t');
                } else if (error.error.message) {
                    errorMessage = error.error.message;
                }
                ctx.patchState({ loading: LoadingOrientationState.notLoading, error: errorMessage });
                return of(error);
            })
        );
    }

    @Action(UpdateOrientation)
    updateOrientation(ctx: StateContext<OrientationStateModel>, action: UpdateOrientation) {
        ctx.patchState({ loading: LoadingOrientationState.loadingAddUpdate });
        return this.orientationService.update(action.payload.id, action.payload).pipe(
            tap((result: any) => {
                const state = ctx.getState();
                const orientations = [...state.orientations];
                const orientationIndex = orientations.findIndex(item => item.id === action.payload.id);
                orientations[orientationIndex] = result.data;
                ctx.patchState({ orientations, loading: LoadingOrientationState.notLoading });
            }),
            catchError((error) => {
                ctx.patchState({ loading: LoadingOrientationState.notLoading, error: error.message });
                return of(error);
            })
        );
    }


    @Action(DeleteOrientation)
    deleteOrientation(ctx: StateContext<OrientationStateModel>, action: DeleteOrientation) {
        ctx.patchState({ loading: LoadingOrientationState.loadingDelete });
        return this.orientationService.remove(action.id).pipe(
            tap(() => {
                const state = ctx.getState();
                const filteredOrientations = state.orientations.filter(orientation => orientation.id !== action.id);
                ctx.patchState({ orientations: filteredOrientations, loading: LoadingOrientationState.notLoading });
            }),
            catchError((error) => {
                ctx.patchState({ loading: LoadingOrientationState.notLoading, error: error.message });
                return of(error);
            })
        );
    }

    @Action(GetOrientations)
    getOrientations(ctx: StateContext<OrientationStateModel>, action: GetOrientations) {
        ctx.patchState({ loading: LoadingOrientationState.loadingList });
        return this.orientationService.all(action.payload).pipe(
            tap((orientations: any) => {
                console.log('Orientations:::', orientations);
                ctx.patchState({ orientations: orientations, loading: LoadingOrientationState.notLoading });
            }),
            catchError((error) => {
                ctx.patchState({ loading: LoadingOrientationState.notLoading, error: error.message });
                return of(error);
            })
        );
    }

    @Action(GetAvailableOrientations)
    getAvailableOrientations(ctx: StateContext<OrientationStateModel>, action: GetAvailableOrientations) {
        ctx.patchState({ loading: LoadingOrientationState.loadingList });
        return this.orientationService.getAvailableOrientations(action.type, 'upcoming').pipe(
            tap((orientations: any) => {
                console.log('Available Orientations:::', orientations);
                ctx.patchState({ orientations: orientations, loading: LoadingOrientationState.notLoading });
            }),
            catchError((error) => {
                ctx.patchState({ loading: LoadingOrientationState.notLoading, error: error.message });
                return of(error);
            })
        );
    }


    @Action(UpdateOrientationStatus)
    updateOrientationStatus(ctx: StateContext<OrientationStateModel>, action: UpdateOrientationStatus) {
        ctx.patchState({ loading: LoadingOrientationState.loadingAddUpdate });
        return this.orientationService.updateOrientationStatus(action.payload).pipe(
            tap((result: any) => {
                console.log('Is Active Success::', result.data);
                const state = ctx.getState();
                const orientations = [...state.orientations];
                const orientationIndex = orientations.findIndex(item => item.id === action.payload.id);
                orientations[orientationIndex] = result.data;
                ctx.patchState({ orientations, loading: LoadingOrientationState.notLoading });
            }),
            catchError((error) => {
                ctx.patchState({ loading: LoadingOrientationState.notLoading, error: error.message });
                return of(error);
            })
        );
    }

    @Action(SetOrientationLoading)
    setLoading(ctx: StateContext<OrientationStateModel>, action: SetOrientationLoading) {
        ctx.patchState({ loading: action.loading });
    }

    @Action(SetOrientationError)
    setError(ctx: StateContext<OrientationStateModel>, action: SetOrientationError) {
        ctx.patchState({ error: action.error });
    }
}