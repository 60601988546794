import { UserModel } from "@tsin-core/models/auth.model";
import { filter, Observable } from "rxjs";

export function copy(...objects: any[]) {
    return Object.assign({}, ...objects);
}

export const filterNil = <T>() => (source: Observable<T>): Observable<NonNullable<T>> => source.pipe(
    filter<T, NonNullable<T>>((value: T): value is NonNullable<T> => value !== null && value !== undefined),
);

export function formatDateToISOString(date: Date): string {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-') + 'T00:00:00';
}

// export function isAdmin(user?: UserModel): boolean {
//     if (!user) {
//         return false;
//     }

//     return user.role === USER_ROLES.SUPER_ADMIN || user.role === USER_ROLES.EXAM_ADMIN || user.role === USER_ROLES.LEARNING_ADMIN || user.role === USER_ROLES.SCHOOL_ADMIN
// }

export function getFormData<T>(dataObject: any): T {
    const formData = new FormData();
    const itemsNames = Object.keys(dataObject);
    itemsNames.forEach((itemName) => {
        const itemValue = dataObject[itemName] ? dataObject[itemName] : '';
        formData.append(itemName, itemValue);
    });
    return formData as unknown as T;
}

export function getDateFromISOString(dateString: string): Date | undefined {
    if (dateString == null)
        return;
    // if(dateString == null ){
    const datePart = dateString.split("T")[0]; // e.x. "1994-09-15"
    return new Date(datePart);
    // }
    // return new Date('2023-06-12');
}
