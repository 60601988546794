import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { SessionRequest } from '@tsin-core/models/session.model';
import { environment } from '@tsin-env/environment';

const SESSION_URL = `${environment.learningBaseUrl}/sessions`;

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  http: HttpClient = inject(HttpClient);

  createSessions(payload: SessionRequest) {
    return this.http.post(SESSION_URL, payload);
  }

  updateSessions(payload: SessionRequest) {
    return this.http.put(SESSION_URL, payload);
  }

  getSessions() {
    return this.http.get(SESSION_URL);
  }

  getSession(id: string) {
    return this.http.get(SESSION_URL + `/single/${id}`);
  }

  deleteSession(id: string) {
    return this.http.delete(SESSION_URL + `/single/${id}`);
  }
}
