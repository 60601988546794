import { LoadingSessionState, SessionRequest } from "@tsin-core/models/session.model";

export class AddSession {
  static readonly type = '[Session] Add';
  constructor(public payload: Omit<SessionRequest, 'id'>) { }
}

export class UpdateSession {
  static readonly type = '[Session] Update';
  constructor(public payload: SessionRequest) { }
}

export class DeleteSession {
  static readonly type = '[Session] Delete';
  constructor(public id: string) { }
}

export class GetSessions {
  static readonly type = '[Session] Get';
}

export class GetSession {
  static readonly type = '[Session] Get One';
  constructor(public id: string) { }
}

export class SetLoading {
  static readonly type = '[Session] Set Loading';
  constructor(public loading: LoadingSessionState) { }
}

export class SetError {
  static readonly type = '[Session] Set Error';
  constructor(public error: string | null) { }
}
