export interface FacilitatorStateModel {
    facilitators: FacilitatorModel[];
    loading: LoadingFacilitatorState;
    facilitatorSchedules: any[];
    selectedFacilitator: FacilitatorModel | null;
    error: any | null;
}

export interface FacilitatorModel {
    authToken: string | null;
    token: string | null;
    id: string | null,
    email: string | null,
    isOtpEnabled: boolean,
    otpMeans: "App" | "Email" | "None",
    isStaff: boolean,
    tracks: string[];
    firstName: string;
    lastName: string;
    sessionIds: string[],
    weeklyScheduleId: string;
    caseIds: string[];
    assignments: FacilitatorAssignment[]
}


export interface FacilitatorAssignment {
    id: string;
    facilitatorId: string;
    track: string;
    sessionId: string;
    caseId: string;
    weeklyScheduleId: string;
}


export interface FacilitatorUser {
    id: string,
    userId: string,
    firstName: string,
    lastName: string,
    caseIds: string[]
}

export interface FacilitatorRequest {
    id?: string
    userId?: string
    firstName: string
    lastName: string
    email: string
    office: string
    role: string
    gender?: "Female" | "Male"
    schoolId?: string
    password: string
    specialty: string
    location: string
}

export interface AssignFacilitatorRequest {
    id: string;
    facilitatorId: string;
    track: string;
    weeklyScheduleId: string;
    sessionId: string;
    caseId: string;
}

export enum LoadingFacilitatorState {
    loadingList = "loadingList",
    loadingAddUpdate = "loadingAddUpdate",
    loadingDelete = "loadingDelete",
    notLoading = "notLoading",
}