import { inject, Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { ApplicationModel, ApplicationStatusUpdateRequest, ApplicationSubmitRequest, ReassignLearnersRequest } from '@tsin-core/models/application.model';
import { LearningType } from '@tsin-core/entities';
import { getFormData } from '@tsin-core/utils';
import { environment } from '@tsin-env/environment';

const APPLICATION_API_URL = `${environment.learningBaseUrl}/applications`;
const APPLICATION_CHECK_IS_REGISTER_API_URL = `${environment.learningBaseUrl}/applications`;
const APPLICATION_BY_LEARNER_API_URL = `${environment.learningBaseUrl}/applications/learner`;

@Injectable({
    providedIn: 'root'
})

export class ApplicationService {

    private http: HttpClient = inject(HttpClient);

    addApplication(data: ApplicationSubmitRequest): Observable<{ data: ApplicationModel, sponsored: 'Y' | 'N' }> {
        return this.http.post<{ data: ApplicationModel, sponsored: 'Y' | 'N' }>(APPLICATION_API_URL, data);
    }

    updateApplication(learnerId: string, data: Partial<ApplicationSubmitRequest>) {
        const fd = getFormData(data);
        return this.http.put(`${APPLICATION_API_URL}/${learnerId}`, fd);
    }

    reassignLearners(data: ReassignLearnersRequest): Observable<any> {
        return this.http.post<any>(`${APPLICATION_API_URL}/reassign-learningId`, data);
    }

    updateLearnersStatus(data: ApplicationStatusUpdateRequest) {
        return this.http.patch(`${APPLICATION_API_URL}/update-application-status`, data);
    }

    getSingleApplication(appId: string) {
        return this.http.get(`${APPLICATION_API_URL}/single/${appId}`)
    }

    getApplicationByLearner(type: LearningType) {
        return this.http.get(APPLICATION_BY_LEARNER_API_URL, {
            params: {
                type: type
            }
        })
    }

    checkIsUserHasRegistrationsToType(type: LearningType) {
        return this.http.get<{ code: { registered: boolean } }>(APPLICATION_CHECK_IS_REGISTER_API_URL, {
            params: {
                type: type
            }
        })
    }

    cancelLearnerRegistration(learnerId: string) {
        return this.http.put(`${APPLICATION_API_URL}/${learnerId}`, { status: 'notRegistered' });
    }
}
