import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@tsin-env/environment';

const SCHOOLS = `${environment.learningBaseUrl}/schools`;

@Injectable({
  providedIn: 'root'
})
export class SchoolsService {

  // Schools
  // POST
  // /api/schools
  // PUT
  // /api/schools
  // GET
  // /api/schools
  // GET
  // /api/schools/single/{id}
  // DELETE
  // /api/schools/single/{id}

  http: HttpClient = inject(HttpClient);

  addSchool(payload: any) {
    return this.http.post(SCHOOLS, payload);
  }

  updateSchool(payload: any) {
    return this.http.put(SCHOOLS, payload);
  }

  getSchools() {
    return this.http.get(SCHOOLS);
  }

  getSingleSchool(schoolId: string) {
    return this.http.get(`${SCHOOLS}/single/${schoolId}`);
  }

  deleteSingleSchool(schoolId: string) {
    return this.http.delete(`${SCHOOLS}/single/${schoolId}`);
  }


}
