import { inject, Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  AddSession,
  DeleteSession,
  GetSessions,
  SetError,
  SetLoading,
  UpdateSession,
} from '@tsin-core/actions/session.action';
import { LoadingSessionState, SessionStateModel } from '@tsin-core/models/session.model';
import { SessionService } from '@tsin-core/services/http/session.service';
import { catchError, of, tap } from 'rxjs';

@State<SessionStateModel>({
  name: 'sessionState',
  defaults: {
    loading: LoadingSessionState.loadingList,
    sessions: [],
    error: null,
  },
})
@Injectable()
export class SessionState {
  sessionService: SessionService = inject(SessionService);

  @Selector()
  static getSession(state: SessionStateModel) {
    return state.sessions;
  }

  @Selector()
  static getLoading(state: SessionStateModel) {
    return state.loading;
  }

  @Selector()
  static getError(state: SessionStateModel) {
    return state.error;
  }

  @Action(AddSession)
  addSession(ctx: StateContext<SessionStateModel>, action: AddSession) {
    ctx.patchState({ loading: LoadingSessionState.loadingAddUpdate, error: null });
    return this.sessionService.createSessions(action.payload).pipe(
      tap((result: any) => {
        const state = ctx.getState();
        ctx.patchState({
          sessions: [...state.sessions, result],
          loading: LoadingSessionState.notLoading,
        });
      }),
      catchError((error) => {
        // console.warn("ERROR:: ", error);
        ctx.patchState({ loading: LoadingSessionState.notLoading, error: error.message });
        throw error;
        // return of(error);
      })
    );
  }

  @Action(UpdateSession)
  updateSession(ctx: StateContext<SessionStateModel>, action: UpdateSession) {

    ctx.patchState({ loading: LoadingSessionState.loadingAddUpdate, });
    return this.sessionService.updateSessions(action.payload).pipe(
      tap((result: any) => {
        const state = ctx.getState();

        const sessions = [...state.sessions];
        const sessionIndex = sessions.findIndex(item => item.id === action.payload.id);
        sessions[sessionIndex].learningId = result;
        ctx.patchState({ sessions, loading: LoadingSessionState.notLoading, error: null });
      }),
      catchError((error) => {
        ctx.patchState({ loading: LoadingSessionState.notLoading, error: error.message });
        return of(error);
      })
    );
  }

  @Action(DeleteSession)
  deleteSession(ctx: StateContext<SessionStateModel>, action: DeleteSession) {
    ctx.patchState({ loading: LoadingSessionState.loadingDelete, error: null });
    return this.sessionService.deleteSession(action.id).pipe(
      tap((result: any) => {
        const state = ctx.getState();
        const filteredSessions = state.sessions.filter(session => session.id !== action.id);
        ctx.patchState({ sessions: filteredSessions, loading: LoadingSessionState.notLoading });
      }),
      catchError((error) => {
        ctx.patchState({ loading: LoadingSessionState.notLoading, error: error.message });
        // return of(error);
        throw error;
      })
    )
  }

  @Action(GetSessions)
  getSessions(ctx: StateContext<SessionStateModel>) {
    ctx.patchState({ loading: LoadingSessionState.loadingList, });
    return this.sessionService.getSessions().pipe(
      tap((sessions: any) => {
        ctx.patchState({ sessions: sessions.data, loading: LoadingSessionState.notLoading });
      }),
      catchError((error) => {
        ctx.patchState({ loading: LoadingSessionState.notLoading, error: error.message });
        return of(error);
      })
    );
  }

  @Action(SetLoading)
  setLoading(ctx: StateContext<SessionStateModel>, action: SetLoading) {
    ctx.patchState({ loading: action.loading });
  }

  @Action(SetError)
  setError(ctx: StateContext<SessionStateModel>, action: SetError) {
    ctx.patchState({ error: action.error });
  }
}