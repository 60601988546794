import { AssignFacilitatorRequest, FacilitatorRequest, LoadingFacilitatorState } from "@tsin-core/models/facilitator.model";
import { ScheduleByIdSessionRequest } from "@tsin-core/models/schedule.model";

export class AddFacilitator {
    static readonly type = '[Facilitator] Add';
    constructor(public payload: Omit<FacilitatorRequest, 'id'>) { }
}

export class AssignFacilitator {
    static readonly type = '[Facilitator] Assign Facilitators';
    constructor(public payload: Omit<AssignFacilitatorRequest, 'id'>) { }
}

export class UnAssignFacilitator {
    static readonly type = '[Facilitator] UnAssign Facilitators';
    constructor(public payload: Omit<AssignFacilitatorRequest, 'id'>) { }
}

export class GetFacilitatorsInSchedule {
    static readonly type = '[Facilitator] Get Facilitators By WeeklyId';
    constructor(public weeklyScheduleId: string) { }
}

export class UpdateFacilitator {
    static readonly type = '[Facilitator] Update';
    constructor(public payload: FacilitatorRequest) { }
}

export class DeleteFacilitator {
    static readonly type = '[Facilitator] Delete';
    constructor(public id: string) { }
}

export class GetFacilitators {
    static readonly type = '[Facilitator] Get';
}

export class GetFacilitator {
    static readonly type = '[Facilitator] Get One';
    // constructor(public id: string) { }
}

export class GetSingleFacilitator {
    static readonly type = '[Facilitator] Get Single By Id';
    constructor(public id: string) { }

}

export class SetLoading {
    static readonly type = '[Facilitator] Set Loading';
    constructor(public loading: LoadingFacilitatorState) { }
}

export class SetError {
    static readonly type = '[Facilitator] Set Error';
    constructor(public error: string | null) { }
}