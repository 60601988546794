import { inject, Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { AddAssessment, GetAssessment, ListAssessmentUsers, SelectAssessmentUser, SetError, SetLoading } from "@tsin-core/actions/assessment.action";
import { AssessmentStateModel, LoadingAssessmentState } from "@tsin-core/models/assessment.model";
import { AssessmentService } from "@tsin-core/services/http/assessment.service";
import { catchError, of, tap } from "rxjs";


@State<AssessmentStateModel>({
  name: 'assessmentState',
  defaults: {
    loading: LoadingAssessmentState.loadingList,
    assessment: [],
    userList: [],
    selectedUser: null,
    error: null,
  }
})
@Injectable()
export class AssessmentState {
  assessmentService: AssessmentService = inject(AssessmentService);

  @Selector()
  static getAssessment(state: AssessmentStateModel){
    return state.assessment;
  }

  @Selector()
  static getAssessmentUser(state: AssessmentStateModel){
    return state.selectedUser;
  }

  
  @Selector()
  static getAssessmentUsers(state: AssessmentStateModel){
    return state.userList;
  }

  @Selector()
  static getLoading(state: AssessmentStateModel){
    return state.loading;
  }

  @Selector()
  static getError(state: AssessmentStateModel){
    return state.error
  }

  @Action(SelectAssessmentUser)
  selectEntity(ctx: StateContext<AssessmentStateModel>, action: SelectAssessmentUser) {
    const state = ctx.getState();
    const selected = state.userList.find(entity => entity.id === action.id);
    ctx.patchState({
      selectedUser: selected
    });
  }

  @Action(AddAssessment)
  addAssessment(ctx: StateContext<AssessmentStateModel>, action: AddAssessment){
    ctx.patchState({loading: LoadingAssessmentState.loadingList});
    return this.assessmentService.addAssessment(action.payload).pipe(
      tap((result: any) => {
        const state = ctx.getState();
        ctx.patchState({
          assessment: [...state.assessment, result],
          loading: LoadingAssessmentState.notLoading
        })
      }),
      catchError((error) => {
        ctx.patchState({
          loading: LoadingAssessmentState.notLoading,
          error: error.message
        });
        return of(error)
      })
    )
  }

  @Action(GetAssessment)
  getAssessment(ctx: StateContext<AssessmentStateModel>, action: GetAssessment){
    ctx.patchState({loading: LoadingAssessmentState.loadingList});
    return this.assessmentService.getAssessment(action.id).pipe(
      tap((result: any) => {
        const state = ctx.getState();
        ctx.patchState({
          assessment:  result.data ,
          loading: LoadingAssessmentState.notLoading
        })
      }),
      catchError((error) => {
        ctx.patchState({
          loading: LoadingAssessmentState.notLoading,
          error: error.message
        });
        return of(error)
      })
    )
  }


  @Action(ListAssessmentUsers)
  getAssessmentList(ctx: StateContext<AssessmentStateModel>, action: ListAssessmentUsers){
    ctx.patchState({loading: LoadingAssessmentState.loadingList});
    return this.assessmentService.getAssessmentList(action.payload).pipe(
      tap((result: any) => {
        const state = ctx.getState();
        ctx.patchState({
          userList: result,
          loading: LoadingAssessmentState.notLoading
        })
      }),
      catchError((error) => {
        ctx.patchState({
          loading: LoadingAssessmentState.notLoading,
          error: error.message
        });
        return of(error)
      })
    )
  }


  @Action(SetLoading)
  setLoading(ctx: StateContext<AssessmentStateModel>, action: SetLoading) {
    ctx.patchState({ loading: action.loading });
  }

  @Action(SetError)
  setError(ctx: StateContext<AssessmentStateModel>, action: SetError) {
    ctx.patchState({ error: action.error });
  }

}