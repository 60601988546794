import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { TrackManagementRequest } from '@tsin-core/models/track-management.model';
import { environment } from '@tsin-env/environment';

const TASK_URL = `${environment.learningBaseUrl}/tracks`;

@Injectable({
  providedIn: 'root',
})
export class TrackManagementService {
  http: HttpClient = inject(HttpClient);

  createTask(payload: TrackManagementRequest) {
    return this.http.post(TASK_URL, payload);
  }

  getTasks() {
    return this.http.get(TASK_URL);
  }

  updateTrack(payload: TrackManagementRequest) {
    return this.http.put(TASK_URL, payload);
  }

  getSingleTrack(id: string) {
    return this.http.get(TASK_URL + `/single/${id}`);
  }

  deleteSingleTrack(id: string) {
    return this.http.delete(TASK_URL + `/single/${id}`);
  }

  numberofCases(id: number) {
    return this.http.get(TASK_URL + `/cases/${id}`);
  }
}
