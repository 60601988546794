export const environment = {
  production: false,
  // userDevUrl: 'https://zxkbbj3pcy.us-east-1.awsapprunner.com/api',
  userBaseUrl: 'https://zxkbbj3pcy.us-east-1.awsapprunner.com/api',
  userMgtUrl: 'https://d2xhgoo376k0s0.cloudfront.net/login/learning',
  paymentUrl: 'https://payment-dev.tsin.ca',
  // paymentUrl: 'http://localhost:4400',
  learningBaseUrl: 'https://learning-dev-be.tsin.ca/api',
  salt: 'ZC\f?.Kp[jnhXf3-"<f3{/tZS@#~(8on',
  oldApiUrl: 'https://portalstage-be.tsin.ca/api/',
  recaptchaSiteKey: '6LeGUecqAAAAAG7TT48TUcRNZTvZn7bTlZC9W5FP',
  jiraLink: 'https://tsin.atlassian.net/servicedesk/customer/portal/8'
};
